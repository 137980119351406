import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility } from '@material-ui/icons';
import moment from 'moment';
import MUIDataTable from 'mui-datatables';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(1),
    },
    icon: {
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            top: -10
        },
    },
    chip: {
        marginRight: theme.spacing(1),
        background: theme.palette.info.main,
        color: 'white'
    }
}));


export default function DepositList(props) {
    const classes = useStyles();

    const actionRenderer = () => {
        return ({
            name: 'id',
            label: 'Wallets',
            options: {
                filter: false,
                customBodyRender: (value) => {
                    let user = props.deposits.filter((item) => {
                        return item.id === value;
                    });
                    return (
                        <Visibility
                            className={classes.icon}
                            color="secondary"
                            onClick={() => props.onDetailsOpen(user[0])}
                        />
                    );
                }
            }
        });
    };

    const statusRenderer = () => {
        return ({
            name: 'status',
            label: 'Status',
            options: {
                setCellProps: () => ({
                    align: "center"
                }),
                filter: true,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <Chip label={value}
                            style={{
                                background: value === "Approved" ? "#4AA96C"
                                    : value === "Rejected" ?
                                        "#BB371A" : "#EBA83A"
                            }}
                        />
                    );
                }
            }
        });
    };

    const columns = [
        {
            name: 'email',
            label: 'Email',
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: 'createdDate',
            label: 'Deposit Time',
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: 'coins',
            label: 'Transfered Coins',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        {
            name: 'transactionCharge',
            label: 'Transaction Charge',
            options: {
                filter: false,
                sort: false,
                setCellHeaderProps: () => ({
                    align: "right"
                }),
                setCellProps: () => ({
                    align: "right"
                })
            }
        },
        statusRenderer(),
        actionRenderer()
    ];

    const tenants = {}

    props.users.forEach((x) => {
        tenants[x.id] = x.tenantName;
    })


    const data = props.deposits.map((item) => {
        return {
            id: item.id,
            createdDate: moment(item.createdOn).format("lll"),
            transactionCharge: item.transactionCharge + ' ' + item.type,
            coins: item.coins + ' ' + item.type,
            status: item.status,
            fromAddress: item.fromAddress,
            email: tenants[item.tenantId]
        };
    });

    return (
        <div className={classes.container}>
            <MUIDataTable
                title={'Deposit`s List'}
                data={data}
                columns={columns}
                options={{
                    filterType: 'checkbox',
                    selectableRows: 'none',
                    rowsPerPageOptions: [10],
                }}
            />
        </div>
    );
}