import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { getBlockChainUrl, getTransactionUrl } from '../../helpers/utility';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function WithdrawDetail(props) {
    const { withdraw, tenants } = props;
    if (!withdraw.type) {
        return <span />
    }

    return (
        <div>
            <Dialog onClose={() => props.onClose()} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={() => props.onClose()}>
                    Withdraw Detail
                </DialogTitle>
                <DialogContent dividers>
                    <table id="product">
                        <tbody>
                            <tr>
                                <th>Tenant</th>
                                <td>{tenants[withdraw.tenantId]}</td>
                            </tr>
                            <tr>
                                <th>Customer ID</th>
                                <td>{withdraw.cid}</td>
                            </tr>
                            <tr>
                                <th>Customer Name</th>
                                <td>{withdraw.name}</td>
                            </tr>
                            <tr>
                                <th>Customer Email</th>
                                <td>{withdraw.email}</td>
                            </tr>
                            <tr>
                                <th>Customer Mobile</th>
                                <td>{withdraw.mobile}</td>
                            </tr>
                            <tr>
                                <th>To Address</th>
                                <td>
                                    <a href={getBlockChainUrl(withdraw.type) + withdraw.address} rel="noopener noreferrer" target="_blank">{withdraw.address}</a>
                                </td>
                            </tr>
                            <tr>
                                <th>Transfered Coins</th>
                                <td>{withdraw.coins} {withdraw.type} ({withdraw.amount} {withdraw.currency})</td>
                            </tr>
                            <tr>
                                <th>Transaction Charge</th>
                                <td>{withdraw.transactionCharge} {withdraw.type}</td>
                            </tr>
                            <tr>
                                <th>Market Value</th>
                                <td>{withdraw.marketValue} {withdraw.currency}</td>
                            </tr>
                            <tr>
                                <th>Transaction Time</th>
                                <td>{moment(withdraw.createdOn).format("lll")}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>{withdraw.status}</td>
                            </tr>
                            <tr>
                                <th>Reference No</th>
                                <td>{withdraw.txnid}</td>
                            </tr>
                            <tr>
                                <th>Auth Domain</th>
                                <td>{withdraw.domainName}</td>
                            </tr>
                            <tr>
                                <th>Transaction Hash</th>
                                <td>
                                    <a style={{ "wordBreak": "break-all" }} href={getTransactionUrl(withdraw.type) + withdraw.transactionhash} rel="noopener noreferrer" target="_blank">{withdraw.transactionhash}</a>
                                </td>
                            </tr>
                            <tr>
                                <th>Transaction Charge Hash</th>
                                <td>
                                    <a style={{ "wordBreak": "break-all" }} href={getTransactionUrl(withdraw.type) + withdraw.parentTransactionHash} rel="noopener noreferrer" target="_blank">{withdraw.parentTransactionHash}</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => props.onClose()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
