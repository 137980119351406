import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { getBlockChainUrl, getTransactionUrl } from '../../helpers/utility';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

export default function DepositDetail(props) {
    const { deposit, tenants } = props;
    if (!deposit.type) {
        return <span />
    }

    return (
        <div>
            <Dialog onClose={() => props.onClose()} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={() => props.onClose()}>
                    Deposit Detail
                </DialogTitle>
                <DialogContent dividers>
                    <table id="product">
                        <tbody>
                            <tr>
                                <th>Tenant</th>
                                <td>{tenants[deposit.tenantId]}</td>
                            </tr>
                            <tr>
                                <th>From Address</th>
                                <td>
                                    <a href={getBlockChainUrl(deposit.type) + deposit.address} rel="noopener noreferrer" target="_blank">{deposit.address}</a>
                                </td>
                            </tr>
                            <tr>
                                <th>Transfered Coins</th>
                                <td>{deposit.coins} {deposit.type} ({deposit.amount} {deposit.currency})</td>
                            </tr>
                            <tr>
                                <th>Transaction Charge</th>
                                <td>{deposit.transactionCharge} {deposit.type}</td>
                            </tr>
                            <tr>
                                <th>Market Value</th>
                                <td>{deposit.marketValue} {deposit.currency}</td>
                            </tr>
                            <tr>
                                <th>Transaction Time</th>
                                <td>{moment(deposit.createdOn).format("lll")}</td>
                            </tr>
                            <tr>
                                <th>Status</th>
                                <td>{deposit.status}</td>
                            </tr>
                            <tr>
                                <th>Transaction Hash</th>
                                <td>
                                    <a style={{ "wordBreak": "break-all" }} href={getTransactionUrl(deposit.type) + deposit.transactionhash} rel="noopener noreferrer" target="_blank">{deposit.transactionhash}</a>
                                </td>
                            </tr>
                            <tr>
                                <th>Transaction Charge Hash</th>
                                <td>
                                    <a style={{ "wordBreak": "break-all" }} href={getTransactionUrl(deposit.type) + deposit.parentTransactionHash} rel="noopener noreferrer" target="_blank">{deposit.parentTransactionHash}</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => props.onClose()} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
