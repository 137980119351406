import HttpHelper from '../../helpers/httpHelper';
import { IsNullOrEmpty } from '../../helpers/utility';
require('dotenv').config();
const headerInfo = {};
const limit = 1000;
class SettingAPI {
    static async LoadSetting() {
        let url = process.env.REACT_APP_API_URL + '/setting';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async UpdateSetting(data) {
        let url = process.env.REACT_APP_API_URL + '/setting';
        return HttpHelper.httpRequest(url, 'PUT', headerInfo, data);
    }

    static async LoadAdminSetting() {
        let url = process.env.REACT_APP_API_URL + '/adminsetting';
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async FetchTransactions(options) {
        const { type, uid, startBy } = options;

        let url = process.env.REACT_APP_API_URL + `/transactions?mode=view`;
        if (!IsNullOrEmpty(type)) {
            url = url + `&type=${type}`
        }
        if (!IsNullOrEmpty(uid)) {
            url = url + `&id=${uid}`
        }
        if (!IsNullOrEmpty(startBy)) {
            url = url + `&limit=${limit}`
            url = url + `&startAt=${startBy}`
        }
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async FetchDeposits(options) {
        const { type, uid, startBy } = options;
        let url = process.env.REACT_APP_API_URL + `/deposits?mode=view`;
        if (!IsNullOrEmpty(type)) {
            url = url + `&type=${type}`
        }
        if (!IsNullOrEmpty(uid)) {
            url = url + `&id=${uid}`
        }
        if (!IsNullOrEmpty(startBy)) {
            url = url + `&limit=${limit}`
            url = url + `&startAt=${startBy}`
        }
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }

    static async FetchWithdraws(options) {
        const { type, uid, startBy } = options;

        let url = process.env.REACT_APP_API_URL + `/requests?mode=view`;
        if (!IsNullOrEmpty(type)) {
            url = url + `&type=${type}`
        }
        if (!IsNullOrEmpty(uid)) {
            url = url + `&id=${uid}`
        }
        if (!IsNullOrEmpty(startBy)) {
            url = url + `&limit=${limit}`
            url = url + `&startAt=${startBy}`
        }
        return HttpHelper.httpRequest(url, 'GET', headerInfo);
    }
}

export default SettingAPI;
